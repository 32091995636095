<template>
  <transition name="navTrans">
    <div 
      class="navigation"
      :class="date !== '' ? 'moreSpace' : ''"
    >
      <h3>Filtermöglichkeiten</h3>
      <ul>
        <li>
          <label for="week">Filtern nach Lebenswoche:</label>
          <br />
          <select
            v-model="tester"
            name="week" 
            id="week"
            @change="monthInput='', exactDateInput='', setDate($event)"
          >
            <option value="">Alles anzeigen</option>
            <option 
              v-for="(week,idx) in weeks"
              :key="idx"
              :value="week"
            >
              {{idx + 1}}. Lebenswoche
            </option>
          </select>
        </li>
        <li>
          <label for="month">Filtern nach Alter:</label>
          <br />
          <select
            v-model="monthInput"
            name="month" 
            id="month"
            @change="tester='', exactDateInput='', setMonth($event) "
          >
            <option value="">Alles anzeigen</option>
            <option 
              v-for="(month,idx) in months"
              :key="idx"
              :value="month"
            >
              <template
                v-if="idx === 1"
              >
                {{idx}} Monat alt
              </template>
              <template
                v-else
              >
                {{idx}} Monate alt
              </template>

            </option>
          </select>
        </li>
        <li v-if="isAdmin">
          <label for="exactDate">Datum wählen:</label>
          <br />
          <input
            v-model="exactDateInput"
            name="exactDate" 
            id="exactDate"
            type="date"
            :max="moment().format('YYYY-MM-DD')"
            @change="tester='', monthInput='', setExactDate($event) "
          />
            
        </li>
        <li>
          <label for="typeDropdown">Filtern Medientyp:</label>
          <br />
          <select
            v-model="typeInput"
            name="typeDropdown" 
            id="typeDropdown"
            @change="setType"
          >
            <option value="both">Alles anzeigen</option>
            <option value="image">Nur Bilder</option>
            <option value="video">Nur Videos</option>
          </select>
        </li>
        <li>
            <div class="showFavsContainer">
              <div id="showFavsLabel">Favoriten anzeigen:</div>
              <input type="checkbox" v-model="showFavInput" id="showFavs" value="showFavs" @change="setFav" />
              <label for="showFavs"></label>
            </div>
        </li>
        <li>
            <button @click="resetFilter" class="invert">Alle Filter zurücksetzen</button>
        </li>
      </ul>
      <small v-if="userName">User: {{userName}}</small>
      <small v-else>ID: {{userid}}</small>
    </div>
  </transition>
</template>

<script>

import moment from 'moment';

export default {
  name: 'Nav',
  data: () => ({
    weeks: [],
    tester:'',

    months: [],
    monthInput:'',

    exactDateInput:'',

    typeInput:'both',

    showFavInput: ''
  }),
  props: {
    date: String,
    userid: String,
    isAdmin: Boolean,
    userName: String
  },
  created(){
    this.setWeeks();
    this.setMonths();
  },
  methods: {
    liveWeeks() {
      const start = moment("2021-02-03");
      const end = moment();
      return end.diff(start,'weeks');
    },

    timespans(weeks, interval = 7, unit = 'days') {
      const start = moment("2021-02-03");
      const dates = [];

      let i;
      dates.push(start.format("YYYY-MM-DD"));
      for (i = 1; i <= weeks; i++) {
        dates.push(start.add(interval, unit).format("YYYY-MM-DD"));
      }
      return dates;
    },
    timespanMonth(weeks, interval = 7, unit = 'days') {
      const start = moment("2021-02-03");
      const dates = [];

      let i;
      dates.push(start.format("YYYY-MM-DD"));
      for (i = 1; i <= weeks; i++) {
        dates.push(start.add(interval, unit).format("YYYY-MM-03"));
      }
      return dates;

    },
    setWeeks() {
      const start = moment("2021-02-03");
      const end = moment();
      this.weeks = this.timespans(end.diff(start,'weeks'), 7, 'days');
    },
    setMonths() {
      const start = moment("2021-02-03");
      const end = moment();
      this.months = this.timespanMonth(end.diff(start,'months'), 1, 'month');
    },
    setDate(option){
      let filterValue = option.target.value || 'no filter';
      this.tracking(this.userid,'filter',filterValue);
      this.$emit('setDate',option.target.value)
    },
    setMonth(option){
      let filterValue = option.target.value || 'no filter';
      this.tracking(this.userid,'filter',filterValue);
      this.$emit('setMonth',option.target.value)
    },
    setType(option){
      this.tracking(this.userid,'filter',option.target.value);
      this.$emit('setType',option.target.value)
    },
    setFav(option){
      this.tracking(this.userid,'filter',option.target.value);
      this.$emit('setFav',this.showFavInput)
    },
    setExactDate(option){
      let filterValue = option.target.value || 'no filter';
      this.tracking(this.userid,'filter',filterValue);
      this.$emit('setExactDate',filterValue)
    },
    resetFilter() {
      this.tester = '';
      this.monthInput = '';
      this.exactDateInput = '';
      this.typeInput = 'both'
      this.showFavInput = false
      this.$emit('resetFilter');

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navigation {
  position: fixed;
  top: 82px;
  top: calc(var(--sat) + 40px);
  left: 0;
  width: 100vw;
  height: calc(100vh - 82px);
  height: calc(100vh - var(--sat) - 40px);
  background: #42b983;
  color: white;
  z-index: 9999;
}

.navigation.moreSpace {
  top: calc(var(--sat) + 40px + 22px);
  height: calc(100vh - var(--sat) - 40px - 22px);
}

a {
  color: white;
}

small {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

h3 {
  margin-bottom: 20px;
}

select {
  width: 50vw;
  padding: 5px 10px;
  font-size: 20px;
  background: white;
  border: none;
  color: #42b983;
  margin: 10px 0 ;
}

input[type=date] {
  width: 50vw;
  padding: 3px 10px;
  font-size: 20px;
  background: white;
  border: none;
  color: #42b983;
  margin: 10px 0 ;
  max-height: 34px;
}

/* .navTrans-enter {
  left: -100vw !important;
}

.navTrans-leave-active {
  left: -100vw;
} */

li {
  margin-bottom: 10px;
}

.navTrans-enter-active, .navTrans-leave-active {
  transition: all .3s ease;
}
.navTrans-enter, .navTrans-leave-active /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-100vw);
}

.showFavsContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

#showFavsLabel {
  margin-right: 20px;
}

input[type=checkbox],
input[type=radio]{
    display: none;
}

input[type=checkbox] + label,
input[type=radio] + label {
	display: inline-block;
	width: 50px;
	height: 30px;
	position: relative;
	cursor: pointer;
	appearance:         none;
	-webkit-appearance: none;
	-moz-appearance:    none;
	box-shadow:         inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	-moz-box-shadow:    inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	-o-box-shadow:      inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	border-radius:         15px;
	-webkit-border-radius: 15px;
	-moz-border-radius:    15px;
	-o-border-radius:      15px;
	background-color: #fff;
	padding: 1px;
	margin: 0px;
	transition:         all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition:    all 0.3s ease-out;
	-o-transition:      all 0.3s ease-out;	
	transform:         scale(1);
	-webkit-transform: scale(1); /*Adjust size here*/
	-moz-transform:    scale(1);
	-o-transform:      scale(1);
}

input[type=checkbox]:checked + label,
input[type=radio]:checked + label {
	box-shadow:         inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
	-webkit-box-shadow: inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
	-moz-box-shadow:    inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
	-o-box-shadow:      inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
}

input[type=checkbox] + label:after,
input[type=radio] + label:after {
    left: 0;
	content: " ";
	position: absolute;
	width: 28px;
	height: 28px;
	cursor: pointer;
	border-radius:         15px;
	-webkit-border-radius: 15px;
	-moz-border-radius:    15px;
	-o-border-radius:      15px;
	box-shadow:         0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow:    0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	-o-box-shadow:      0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	/* background-image: url(../images/toggle.png);
	background-repeat: no-repeat;
	background-size: contain; */
	transition:         all 0.2s ease-in 0.2s;
	-webkit-transition: all 0.2s ease-in 0.2s;
	-moz-transition:    all 0.2s ease-in 0.2s;
	-o-transition:      all 0.2s ease-in 0.2s;
}

input[type=checkbox]:checked + label:after,
input[type=radio]:checked + label:after {
	left: 23px;
}

input[type=checkbox]:focus,
input[type=checkbox]:active,
input[type=radio]:focus,
input[type=radio]:active {
	outline: 0;
}

</style>
