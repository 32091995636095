import Vue from 'vue'
import App from './App.vue'

import router from '@/router';

import moment from 'moment'

Vue.prototype.moment = moment

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle, faArrowCircleLeft, faCloudDownloadAlt,faSync,faEllipsisV, faTimes,faList,faTh, faPencilAlt, faStar as fasStar, faInfinity } from '@fortawesome/free-solid-svg-icons'
import { faSave, faTrashAlt, faCalendarAlt, faClock,faPlayCircle, faStar as farStar, faStopCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'
library.add(faPlusCircle,faArrowCircleLeft,faSave,faTrashAlt,faCloudDownloadAlt,faSync,faCalendarAlt,faClock,faPlayCircle,faEllipsisV,faTimes,faList,faTh,faPencilAlt,farStar,fasStar, faInfinity,faStopCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.filter('formatDate', function(value) {
  if (value) {
    moment.locale('de');
    return moment(value).format('DD. MMMM YYYY')
  }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    moment.locale('de');
    return moment(value).format('HH:mm')
  }
})

Vue.mixin({
  methods:{
    formatDate(value, format = 'DD. MMMM YYYY'){
      moment.locale('de');
      return moment(value).format(format)
    },
    async tracking(userid, event, content='') {
      const data = new FormData()
      if (userid) data.append('userid', userid);
      if (event) data.append('event', event);
      if (content) data.append('data', content);
      // for (var value of data.values()) {
      //     console.log(value);
      // }
      const response = await fetch('https://dodoapp.montalto.me/api/tracking.php', { // Your POST endpoint
        method: 'POST',
        body: data // This is your file object
      });
      return response;
    }
  }
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
