<template>
  <div class="editScreen">
    <header>
      <router-link to="/"><font-awesome-icon icon="arrow-circle-left" /></router-link>
    </header>
    <form
      v-if="!showMessage && !imageId"
      enctype="multipart/form-data"
    >
      <label for="fileUpload" class="fileUpload">
        <input type="file" name="file" id="fileUpload" @change="filesChange($event.target.name, $event.target.files)" multiple>
        <span>Bild/Video hochladen</span>
      </label>

      <div class="showFavsContainer">
        <div id="showFavsLabel">Keep awake:</div>
        <input type="checkbox" v-model="keepAwakeVal" id="keepAwake" value="keepAwake" @change="keepAwake" />
        <label for="keepAwake"></label>
      </div>
    </form>


    <div
      v-if="uploadFormat === 'video' && imageId && image && !multiUpload"
      class="video"
      @click="videoHandler($event.target)"
    >
      <video 
        :src="videoUrl(image.image)"
        @ended="onEnd($event.target)"
        playsInline
        preload='metadata'
      >
      </video>
      
      <font-awesome-icon :icon="['far', 'play-circle']" class="playCircle" size="8x" />
    </div>
    <div
      v-if="uploadFormat === 'video' && imageId && image && !multiUpload && conversionState > 0"
    >
      <Button @click="setConversionState()">Reconvert Video</Button>
    </div>
    
    <figure
      v-if="image && uploadFormat === 'image' && !multiUpload"
    >
      <img :src="image.image" alt="">
    </figure>
    <div
      v-if="showMessage && !multiUpload"
      class="message"
    >
    <font-awesome-icon
      icon="sync"
      spin
      size="2x"
      />

      <span> Upload läuft…</span>
      
    </div>  
    <div
      v-if="multiUpload"
      class="message"
    >
    <font-awesome-icon
      icon="sync"
      spin
      size="2x"
      />

      <span> Upload läuft… {{ uploadCount }} von {{ uploadMax }}</span>
      
    </div>   
    <div v-if="(this.imageId || image) && !multiUpload">
      <input
        v-if="image"
        v-model="image.timestamp"
        class="timestamp"
        type="datetime-local"
      >
      <textarea v-model="desc" cols="30" rows="10"></textarea>
      <br>
      <button 
        @click="addDesc()"
      >
        <font-awesome-icon :icon="['far', 'save']" /> Speichern
      </button>
      <button
        class="deleteButton"
        id="show-modal" 
        @click="showModal = true"
      >
        <font-awesome-icon :icon="['far', 'trash-alt']" /> Löschen
      </button>

    </div>

    <modal v-if="showModal" @button1="showModal = false" @button2="deleteImg(image)">
      <h3 slot="header">Bild löschen?</h3>
      <p slot="body">Soll das Bild wirklich unwiderruflich gelöscht werden?</p>
      <span slot="button1">Abbrechen</span>
      <span slot="button2">Löschen</span>
    </modal>

    <modal v-if="showError" @button1="showError = false">
      <h3 slot="header">Wartungsarbeiten</h3>
      <p slot="body">Täglich zwischen 23:50 und 0:20 werden Backups erstellt. In dieser Zeit können keine neuen Inhalte hochgeladen werden.</p>
      <span slot="button1">Ok</span>
    </modal>
    
  </div>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  name: 'Edit',
  components: {
    Modal
  },
  data: () => ({
    video: null,
    videoResponse: null,
    image: null,
    imageId: null,
    desc: '',
    showModal: false,
    showMessage: false,
    showError: false,
    uploadFormat: '',
    uploadCount: 0,
    uploadMax: 0,
    multiUpload: false,
    allFiles: [],
    conversionState: 0,
    keepAwakeVal: false,
    wakeState: null
  }),
  props: {
    isAdmin: Boolean,
    secret: String
  },
  created(){
    if (this.$route.params.imageId) {
      this.getSingle(this.$route.params.imageId);
    }
  },
  methods: {
    onUpload (formData) {
      this.uploadFormat = "image";
      this.showMessage = true;
      fetch('https://dodoapp.montalto.me/api/index.php', { // Your POST endpoint
        method: 'POST',
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
        },
        body: formData // This is your file object
      }).then(
        response => response.json() // if the response is a JSON object
      ).then(
        success => {
          if (success === 'time_error') {
            this.showError = true;
          } else {
            this.imageId = success;
            this.getSingle(this.imageId);
            this.uploadCount++;
            if (this.uploadCount < this.uploadMax) {
              setTimeout(() => {
                this.filesChange('',this.allFiles)
              }, 1000);
            } else {
              this.multiUpload = false;
              this.uploadCount = 0;
              this.$router.push('start')
            }
          }
          this.showMessage = false;
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    videoUpload (formData) {
      this.uploadFormat = 'video';
      this.showMessage = true;
      fetch('https://dodoapp.montalto.me/api/uploadVideo.php', { // Your POST endpoint
        method: 'POST',
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
        },
        body: formData // This is your file object
      }).then(
        response => response.json() // if the response is a JSON object
      ).then(
        success => {
          if (success === 'time_error') {
            this.showError = true;
          } else {
            this.imageId = success;
            this.getSingle(this.imageId);
            this.uploadCount++;
            if (this.uploadCount < this.uploadMax) {
              setTimeout(() => {
                this.filesChange('',this.allFiles)
              }, 1000);
              
            } else {
              this.multiUpload = false;
              this.uploadCount = 0;
              this.$router.push('start')
            }
          }
          this.showMessage = false;
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    addDesc () {
      var data = new FormData()
      data.append('desc', this.desc)
      data.append('image', this.imageId)
      if (this.image?.timestamp) data.append('timestamp', this.image.timestamp.replace("T", " ").replaceAll(":", "-"))
      fetch('https://dodoapp.montalto.me/api/addDesc.php', { // Your POST endpoint
        method: 'POST',
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
        },
        body: data // This is your file object
      }).then(
        response => {
          return response.json() // if the response is a JSON object
        }
      ).then(
        success => {
          console.log(success);
          this.$router.push({ name: 'start'})
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    setConversionState () {
      var data = new FormData()
      data.append('conversion_in_progress', 1)
      data.append('image', this.imageId)
      fetch('https://dodoapp.montalto.me/api/setConversionState.php', { // Your POST endpoint
        method: 'POST',
        headers: {
          // Content-Type may need to be completely **omitted**
          // or you may need something
        },
        body: data // This is your file object
      }).then(
        response => {
          return response.json() // if the response is a JSON object
        }
      ).then(
        success => {
          console.log(success);
          this.$router.push({ name: 'start'})
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    getSingle (id) {
      fetch('https://dodoapp.montalto.me/api/getSingle.php?id='+id, { // Your POST endpoint
        method: 'GET',
      }).then(
        response => {
          return response.json() // if the response is a JSON object
        }
      ).then(
        success => {
          this.image = success;
          this.desc = this.image.description
          this.imageId = this.image.id
          this.uploadFormat = this.image.type
          this.conversionState = this.image.conversion_in_progress
          this.image.timestamp = this.image.timestamp.replace(" ", "T");
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    deleteImg (image) {
      const imageId = image !== null ? image.id : this.imageId;
      fetch('https://dodoapp.montalto.me/api/delete.php?id='+imageId, { // Your POST endpoint
        method: 'GET',
      }).then(
        response => {
          return response.json() // if the response is a JSON object
        }
      ).then(
        success => {
          console.log(success);
          this.$router.push({ name: 'start'})
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    filesChange(fieldName, fileList) {
      this.multiUpload = false;
      if (!fileList.length) return;
      if (fileList.length === 1) {
        this.fileType(fieldName, fileList[0])
      } else {
        this.uploadMax = fileList.length;
        if (this.uploadCount < this.uploadMax) {
          this.multiUpload = true;
          this.allFiles = fileList;
          this.fileType(fieldName, fileList[this.uploadCount]);
        } else {
          this.multiUpload = false;
          this.uploadCount = 0;
        }
        
      }
    },
    fileType(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();
        const type = fileList.type.split("/")[0];
        // save it
        if (type === "image") {
          formData.append('file', fileList);
          this.onUpload(formData);
          
        } else {
          formData.append('video', fileList);
          this.videoUpload(formData);
        } 
          

      },

      videoHandler(video){
      if(video.paused) {
        video.parentElement.classList.add('playing');
        video.play();
      } else {
        video.pause();
        video.parentElement.classList.remove('playing');
      }
    },
    onEnd(video) {
      video.parentElement.classList.remove('playing');
      video.currentTime = 0;
    },
    videoUrl(url){
      return url + '#t=0.001';
    },

    async keepAwake() {
      if (this.keepAwakeVal) {
        this.wakeState = await navigator.wakeLock.request("screen");
        
      } else {
        this.wakeState.release();
      }

      console.log(this.wakeState);
      
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.editScreen {
  background: white;
}

img,video {
  max-width: 600px;
  width: 100%;
  height: auto;
}

.video {
  position: relative;
}

.playCircle {
  position: absolute;
  top:50%;
  left:50%;
  color: white;
  transform: translate(-50%,-50%);
  opacity: .5;
  pointer-events: none;
}

.playing .playCircle {
display: none;
}
.fileUpload {
  position: relative;
  display: inline-block;
  background: white;
  color: #42b983;
  border: 2px solid #42b983;
  border-bottom-width: 4px;
  border-right-width: 4px;
  font-size: 26px;
  font-weight: bold;
  padding: 20px;
  max-width: calc(600px - 70px);
  width: calc(100vw - 70px);
  margin: 20px 0;
}


.fileUpload input {
  display: none;

}

header {
  box-sizing: border-box;
  background: #42b983;
  color: white;
  padding: 50px 20px 10px;
  text-align: left;
  min-height: 82px;
}

header a {
  color: white;
  text-decoration: none;
  font-size: 30px
}
.button {
  width: 100%;
  color: #42b983;
  font-size: 30px;
  text-align: left;
}

.timestamp {
  width: calc(100% - 32px);
  display: inline-block;
  margin: 20px 0;
  border-color: #42b983;
  border-width: 2px;
  border-style: solid;
  font-size: 18px;
}

textarea {
  border-color: #42b983;
  border-width: 2px;
  font-size: 18px;
  width: calc(100% - 32px);
}

.message {
  color: #42b983;
  display: flex;
  justify-content: space-around;
  padding: 20px 0
}

.message span {
  font-size: 26px;
}

.showFavsContainer {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

#showFavsLabel {
  margin-right: 20px;
}

input[type=checkbox],
input[type=radio]{
    display: none;
}

input[type=checkbox] + label,
input[type=radio] + label {
	display: inline-block;
	width: 50px;
	height: 30px;
	position: relative;
	cursor: pointer;
	appearance:         none;
	-webkit-appearance: none;
	-moz-appearance:    none;
	box-shadow:         inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	-moz-box-shadow:    inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	-o-box-shadow:      inset 0px 5px 40px -13px rgba(0, 0, 0, 0.75);
	border-radius:         15px;
	-webkit-border-radius: 15px;
	-moz-border-radius:    15px;
	-o-border-radius:      15px;
	background-color: #fff;
	padding: 1px;
	margin: 0px;
	transition:         all 0.3s ease-out;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition:    all 0.3s ease-out;
	-o-transition:      all 0.3s ease-out;	
	transform:         scale(1);
	-webkit-transform: scale(1); /*Adjust size here*/
	-moz-transform:    scale(1);
	-o-transform:      scale(1);
}

input[type=checkbox]:checked + label,
input[type=radio]:checked + label {
	box-shadow:         inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
	-webkit-box-shadow: inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
	-moz-box-shadow:    inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
	-o-box-shadow:      inset 0px 0px 0px 20px #53d76a, inset 0px 5px 40px -13px rgba(132, 132, 132, 1);
}

input[type=checkbox] + label:after,
input[type=radio] + label:after {
    left: 0;
	content: " ";
	position: absolute;
	width: 28px;
	height: 28px;
	cursor: pointer;
	border-radius:         15px;
	-webkit-border-radius: 15px;
	-moz-border-radius:    15px;
	-o-border-radius:      15px;
	box-shadow:         0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	-moz-box-shadow:    0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	-o-box-shadow:      0px 2px 2px 1px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	/* background-image: url(../images/toggle.png);
	background-repeat: no-repeat;
	background-size: contain; */
	transition:         all 0.2s ease-in 0.2s;
	-webkit-transition: all 0.2s ease-in 0.2s;
	-moz-transition:    all 0.2s ease-in 0.2s;
	-o-transition:      all 0.2s ease-in 0.2s;
}

input[type=checkbox]:checked + label:after,
input[type=radio]:checked + label:after {
	left: 23px;
}

input[type=checkbox]:focus,
input[type=checkbox]:active,
input[type=radio]:focus,
input[type=radio]:active {
	outline: 0;
}
</style>
