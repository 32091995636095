import Vue from 'vue';
import VueRouter from 'vue-router';

import StartView from '@/views/Start';
import EditView from '@/views/Edit';

Vue.use(VueRouter);

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'start',
      component: StartView
    },
    {
      path: '/new',
      name: 'new',
      component: EditView,
      props: true
    },
    {
      path: '/edit/:imageId',
      name: 'edit',
      component: EditView,
      props: true
    },
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior: () => ({ x: 0, y: 0 })
});