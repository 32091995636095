<template>
  <div class="hello">
    <header>
      <font-awesome-icon
      :icon="navOpen ? 'times': 'ellipsis-v'"
      @click="toggleNav()"
      size="lg"
      />
      <div
        v-if="startdate && enddate" 
        class="title"
      >
        <p>{{dateFilterHeadline(startdate,enddate)}}</p>
        <small>{{formatTimespan(startdate,enddate)}}</small>

      </div>

      <font-awesome-icon
      icon="sync"
      :spin="spinning"
      @click="reload()"
      size="lg"
      />
    </header>

    <div
      v-for="image in images"
      :key="image.id"
      class="image-container"
    >

      <img v-if="image.type === 'image'" :src="image.thumbnail" alt="" />

      <div
        v-if="image.type === 'video'"
        class="video"
        @click="videoHandler($event.target,image)"
      >
        <video 
          v-if="image.conversion_in_progress === '0'"
          :src="videoUrl(image.image)"
          :poster="imageUrl(image)"
          :id="`video${image.id}`"
          @ended="onEnd($event.target,image)"
          @timeupdate="videoDuration($event.target,image)"
          playsinline
          preload='auto'
          :loop=image.loop
        >
        </video>

        <img
          v-if="image.conversion_in_progress === '1' || image.conversion_in_progress === '2'"
          :src="image.thumbnail"
          alt="" 
        />

        <div
          v-if="image.conversion_in_progress === '1' || image.conversion_in_progress === '2'"
          class="videoConversionNotice"
        >
          <p v-if="image.conversion_in_progress === '1'">Das Video wird bald verarbeitet. Die Verarbeitung startet innerhalb der nächsten 5 Minuten.</p>
          <p v-if="image.conversion_in_progress === '2'">Das Video wird gerade verarbeitet und kann gleich angesehen werden.</p>
        </div>

        <font-awesome-icon
          v-if="image.conversion_in_progress === '0'"
          :icon="['far', 'play-circle']"
          class="playCircle" 
          size="8x" 
        />
        <div 
          v-if="image.conversion_in_progress === '0'"
          class="videoMeta"
        >
          {{ image.currentTime }}/{{ image.videoDuration}}
        </div>
      </div>
      <p class="meta">
        <time>
          <font-awesome-icon :icon="['far', 'calendar-alt']" /> {{image.timestamp | formatDate}}
          <font-awesome-icon :icon="['far', 'clock']" /> {{image.timestamp | formatTime}}
        </time>
        <button v-if="image.type === 'video'" class="loopButton" @click="loopAction(image)">
          <font-awesome-icon :icon="['fas','infinity']" />
          <span v-if="image.loop" class="loopButton_strike"></span>
        </button>
        <button v-if="isAdmin" @click="editView(image.id)"><font-awesome-icon icon="pencil-alt" /></button>
        <button v-if="image.thumbnail" @click="download(image.thumbnail, image.timestamp)" class="downloadbutton"><font-awesome-icon icon="cloud-download-alt" /></button>
      </p>
      <p v-if="image.description" class="description">{{image.description}}</p>


      <div v-if="isAdmin" class="favStarContainer">
        <button class="favStarContainerButton" @click="favAction(image)">
          <font-awesome-icon :icon="[image.fav ? 'fas' : 'far', 'star']" />
        </button>
      </div>
    </div>

    <div
      v-if="loader"
      class="loader"
    >
      <p>Weitere Bilder werden geladen...</p>
    </div>

    <div v-if="isAdmin" class="add-button">
      <router-link to="/new"><font-awesome-icon icon="plus-circle" /></router-link>
    </div>

    <Nav 
      v-show="navOpen"
      :date="startdate"
      :userid="userid"
      :isAdmin="isAdmin"
      :userName="userName"
      @setDate="setStartEndDate"
      @setMonth="setStartEndDateMonth"
      @setExactDate="setExactDate"
      @setType="setType"
      @setFav="setFav"
      @resetFilter="resetFilter"
    />
  </div>
</template>

<script>
import moment from 'moment';

import Nav from '@/components/Nav'

export default {
  name: 'Start',
  components: {
    Nav
  },
  data: () => ({
    images: [],
    spinning: false,
    maxReached: false,
    loader: false,
    navOpen: false,
    limit: 5,
    startdate: '',
    enddate: '',
    typeFilter: 'both',
    favFilter: false
  }),
  props: {
    isAdmin: Boolean,
    secret: String,
    userid: String,
    userName: String
  },
  computed: {
    safeAreaTop() {
      return getComputedStyle(document.documentElement).getPropertyValue('--sat');
    }
  },
  mounted() {
    this.scroll();
  },
  created() {
    this.pushData();
  },
  methods: {
    pushData() {
      this.loader = true;
      const offset = this.images.length;
      this.getAll(this.limit,offset)
      if (offset) this.tracking(this.userid,'views',offset);

    },
    getAll (limit, offset) {
      
      fetch('https://dodoapp.montalto.me/api/getAll.php?'+ new URLSearchParams({
        secret: this.secret,
        limit: limit,
        offset: offset,
        startdate: this.startdate,
        enddate: this.enddate,
        type: this.typeFilter,
        showFav: this.favFilter,
        user: this.userid,
        isAdmin: this.isAdmin
      }), { // Your POST endpoint
        method: 'GET',
      }).then(
        response => {
          return response.json() // if the response is a JSON object
        }
      ).then(
        success => {
          if (success.length > 0) {
            this.images = [ ...this.images, ...success.map(obj=> ({ ...obj, videoDuration: '0:00', currentTime: '0:00', loop: false }))];
          } else {
            this.maxReached = true;
          }
          this.loader = false;
          
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },
    editView (id){
      this.$router.push({ name: 'edit', params: { imageId: id }})
    },

    reload() {
      this.spinning = true;
      setTimeout(function(){
        location.reload();
      },500);
    },
    videoHandler(video,file){
      if(video.paused) {
        video.parentElement.classList.add('playing');
        this.tracking(this.userid,'play','play::'+file.image);
        video.play();
      } else {
        video.pause();
        this.tracking(this.userid,'play','stop::'+file.image);
        video.parentElement.classList.remove('playing');
      }
    },
    onEnd(video,file) {
      video.parentElement.classList.remove('playing');
      this.tracking(this.userid,'play','end::'+file.image);
      video.currentTime = 0;

      if (this.typeFilter === 'video' && this.isAdmin) {
        const index = this.images.indexOf(file);
        console.log(index);
        if(index >= 0 && index < this.images.length - 1) {
          const nextVideo = this.images[index + 1];
          const video = document.getElementById(`video${nextVideo.id}`);
          //video.scrollIntoView(false);
          this.scrollToTargetAdjusted(video);
          this.videoHandler(video,nextVideo);
        }
      }
    },
    videoUrl(url){
      return url + '#t=0.001';
    },
    imageUrl(image){
      return image.thumbnail !== "" ? image.thumbnail : image.image.slice(0,-12)+".jpg";
    },
    videoDuration(video,image) {
      image.videoDuration = this.formatSeconds(video.duration);
      image.currentTime = this.formatSeconds(video.currentTime);
    },
    formatSeconds(SECONDS) {
      return new Date(SECONDS * 1000).toISOString().substr(14, 5)
    },
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

        if (bottomOfWindow && !this.maxReached && !this.loader) {
          this.pushData();
        }
      };
    },
    toggleNav() {
      if (this.navOpen) {
        this.navOpen = false;
      } else {
        this.navOpen = true;
      }

    },
    setStartEndDate(start) {
      console.log(start); 
      if (start === '') {
        this.startdate = start;
        this.enddate = start;
      } else {
        this.startdate = moment(start).format('YYYY-MM-DD') + " 00:00:00";
        this.enddate = moment(start).add(6,'days').format('YYYY-MM-DD') + " 23:59:59";
      }
      this.images = [];
      this.pushData();
      //this.navOpen = false;
      this.maxReached = false;
    },
    setStartEndDateMonth(start) {
      if (start === '') {
        this.startdate = start;
        this.enddate = start;
      } else {
        this.startdate = moment(start).format('YYYY-MM-DD') + " 00:00:00";
        this.enddate = moment(start).add(1,'month').subtract(1, 'day').format('YYYY-MM-DD') + " 23:59:59";
      }
      this.images = [];
      this.pushData();
      //this.navOpen = false;
      this.maxReached = false;
    },

    setExactDate(date) {
      if (date === '') {
        this.startdate = date;
        this.enddate = date;
      } else {
        this.startdate = moment(date).format('YYYY-MM-DD') + " 00:00:00";
        this.enddate = moment(date).format('YYYY-MM-DD') + " 23:59:59";
      }
      this.images = [];
      this.pushData();
      //this.navOpen = false;
      this.maxReached = false;
    },

    formatTimespan(startdate,enddate) {
      if (moment(startdate).diff(moment(enddate), 'days') === 0) return moment(enddate).format('DD.MM.YYYY');
      return moment(startdate).format('DD.MM') + ' - ' + moment(enddate).format('DD.MM.YYYY');
    },

    calcLifeweek(date) {
      const start = moment("2021-02-03");
      const end = moment(date);
      return end.diff(start,'weeks');
    },

    calcLifemonth(date) {
      const start = moment("2021-02-03");
      const end = moment(date);
      return end.diff(start,'months');
    },

    dateFilterHeadline(startdate, enddate) {
      const start = moment(startdate);
      const end = moment(enddate);
      const days = end.diff(start,'days');

      if (days > 10) {
        const age = this.calcLifemonth(startdate);
        if (age === 1) {
          return `${this.calcLifemonth(startdate)} Monat alt`;
        } 
        return `${this.calcLifemonth(startdate)} Monate alt`;
      } else {
        return `${this.calcLifeweek(startdate) + 1}. Lebenswoche`;
      }
    },

    setType(type) {
      this.typeFilter = type;
      this.images = [];
      this.pushData();
      this.maxReached = false;
    },

    setFav(showFav) {
      this.favFilter = !!showFav;
      this.images = [];
      this.pushData();
      this.maxReached = false;
    },

    resetFilter() {
      this.startdate = '';
      this.enddate = '';
      this.typeFilter = 'both';
      this.favFilter = false;
      this.images = [];
      this.pushData();
      this.maxReached = false;
    },

    download(imageUrl, timestamp) {
      const popup = window.open();
      //const imageName = imageUrl.substring(imageUrl.lastIndexOf('/')+1);
      const imageName = 'marten_'+this.formatDate(timestamp,'DD_MMMM_YYYY_HH:mm')+'Uhr.jpg';
      this.tracking(this.userid,'download',imageUrl);
      fetch(imageUrl)
      .then(function(response) {
        return response.blob() 
      })
      .then(function(blob) {
        const file = new Blob([blob], { type: 'image/jpg' });
        const reader = new FileReader(); 
        reader.onload = function() {           
          const link = document.createElement('a');
          link.setAttribute('href', reader.result);
          link.setAttribute('download', imageName);
          popup.document.body.appendChild(link); 
          link.click(); 
        } 
        reader.readAsDataURL(file); 
      });
      
    },

    favAction(image) {
      var data = new FormData()
      data.append('userid', this.userid)
      data.append('imageid', image.id)
      data.append('favid', image.fav || false )
      fetch('https://dodoapp.montalto.me/api/favs.php', { // Your POST endpoint
        method: 'POST',
        body: data // This is your file object
      }).then(
        response => {
          return response.json() // if the response is a JSON object
        }
      ).then(
        success => {
          console.log(success);
          image.fav = success;
        }
      ).catch(
        error => console.log(error) // Handle the error response object
      );
    },

    loopAction(image) {
      image.loop = !image.loop
    },

    scrollToTargetAdjusted(element){
      const headerOffset = 55 + parseInt(this.safeAreaTop, 10);
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: inherit;
}

header {
  box-sizing: border-box;
  background: #42b983;
  color: white;
  padding: 10px 20px 10px;
  padding-top: calc(var(--sat) + 10px);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  min-height: 45px;
  width: 100%;
  z-index: 9999;
}

header.allRight {
  justify-content: flex-end;
}

header a {
  color: white;
  text-decoration: none;
  font-size: 30px;
}

img,
video {
  width:100%;
  height: auto;
  display: block;
}

.video {
  position: relative;
}

.playCircle {
  position: absolute;
  top:50%;
  left:50%;
  color: white;
  transform: translate(-50%,-50%);
  opacity: .5;
  pointer-events: none;
}

.playing .playCircle {
display: none;
}
.add-button {
  position: sticky;
  bottom: 0;
  width: 100%;
  font-size: 50px;
  padding: 20px 0;
  color: #42b983;
}

.loader {
  padding: 30px 10px;
}

.meta {
  display: flex;
  justify-content: space-between;
  background-color: #42b983;
  color: white;
  align-items: center;
  padding: 0 16px;
  font-size: 15px;
  min-height: 48px;
}

.description {
  padding: 16px;
  background: white;
}

.videoMeta {
  position: absolute;
  bottom: 2px;
  right: 2px;
  color: white;
  pointer-events: none;
}

.downloadbutton {
  font-size: 24px
}

.image-container {
  position: relative;
  border-bottom: 10px solid grey;
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, .5)
}

.favStarContainer {
  position: absolute;
  font-size: 25px;
  top: 10px;
  left: 10px;
  z-index: 1000;
}

.favStarContainerButton {
  color: gold;
  background: none;
  padding: 0;
}

.videoConversionNotice {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #42b983;
  padding: 20px;
  color: white;
}

.loopButton {
  position: relative;
}

.loopButton_strike {
  position: absolute;
  top: 28%;
  left: 35%;
  display: block;
  width: 25px;
  height: 2px;
  background-color: white;
  border: 1px solid #42b983;
  transform-origin: 0 0;
  transform: rotate(30deg);
}
</style>